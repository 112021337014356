@import '../../../../scss/theme-bootstrap';

[data-component='search-gnav-input-field'] > .elc-spinner-wrapper {
  width: 210px;
  height: 38px;
  .elc-spinner-container > div {
    background-color: $cr19-bg-black;
    circle {
      stroke: $color-white;
    }
  }
}

.sd-search-gnav-input-field {
  margin: 0;
  position: relative;
  top: 1px;
  width: 100%;
  @include breakpoint($large-up) {
    position: absolute;
    #{$rdirection}: 30px;
    top: 64px;
    width: auto;
    .elc-search-result-overlay-message {
      margin-bottom: 0;
    }
  }
  .elc-search-active-screen-wrapper {
    background-color: $color-black;
    height: auto;
    #{$ldirection}: 0;
    overflow: auto;
    padding: 0 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    @include breakpoint($medium-up) {
      bottom: initial;
      top: 178px;
      overflow: hidden;
    }
    @include breakpoint($large-up) {
      top: 132px;
    }
    .elc-search-gnav-box {
      background-color: $color-black;
      border: none;
      justify-content: flex-end;
      min-height: 65px;
      padding: 34px 0 24px;
      @include breakpoint($medium-up) {
        padding-bottom: 10px;
      }
      .elc-search-close-icon-wrapper {
        background: transparent;
        position: absolute;
        #{$rdirection}: 18px;
        top: 8px;
      }
      .elc-input-search-field {
        height: 31px;
        padding-#{$ldirection}: 0;
        font-family: $main-font;
        background-color: $color-black;
        border-bottom: 1px solid $color-white;
        border-radius: 0;
        color: $color-white;
        font-size: 24px;
        &::placeholder {
          font-family: $main-font;
        }
      }
      .elc-search-results-button {
        @include elc-button;
        @include elc-button--pink;
        text-decoration: none;
      }
    }
  }
  .elc-gnav-search-button {
    padding: 5px;
    margin-top: 15px;
  }
  .elc-search-icon {
    background-color: $color-white;
    @include breakpoint($medium-up) {
      background-color: $color-black;
    }
  }

  .elc-search-enhanced-wrapper {
    background-color: $color-black;
    padding: 0;
    @include breakpoint($large-up) {
      flex-direction: row-reverse;
    }
    .elc-search-enhanced-trending-terms-wrapper {
      margin-bottom: 12px;
      @include breakpoint($large-up) {
        padding: 0 16px;
        width: 18%;
      }
      .elc-search-trending-term__link {
        @include text-link;
        @include text-link--style-2;
        color: $cr19-text-white;
        padding: 0 5px;
      }
    }
    .elc-search-enhanced-grid-wrapper {
      @include breakpoint($large-up) {
        width: 82%;
      }
    }
    .elc-styled-product-image-container {
      background-color: $cr19-bg-black;
      padding: 0;
    }
  }
  .elc-search-enhanced-trending-title,
  .elc-search-enhanced-grid-title,
  .elc-search-result-overlay-message,
  .elc-search-no-result-message,
  .elc-search-result-adjustment-message {
    @include text-title--med;
    color: $cr19-text-white;
  }
  .elc-search-no-result-message {
    text-align: center;
  }
  .elc-search-result-overlay-message {
    margin: 0;
  }
  .elc-search-enhanced-grid-title {
    margin-bottom: 4px;
  }
  .elc-search-close-icon {
    background-color: $color-white;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    @include breakpoint($medium-up) {
      top: -8px;
    }
  }
  .elc-floating-label-input {
    height: 50px;
    .elc-input-search-gnav-field {
      @include swap_direction(padding, 23px 0 15px 38px);
      background-color: transparent;
      display: block;
      border: none;
      border-bottom: 1px solid $color-white;
      text-transform: none;
      font-size: 14px;
      color: $color-white;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 23px 0 10px 30px);
      }
      font-family: $main-font;
      min-width: 180px;
      &::placeholder {
        color: $color-white;
        font-family: $main-font;
      }
      @include breakpoint($xxlarge-up) {
        min-width: 212px;
      }
    }
  }

  .elc-search-results-wrapper {
    background-color: $color-black;
    border: none;
    @include breakpoint($medium-up) {
      margin-top: -40px;
      grid-template-areas: unset;
      grid-template-columns: unset;
    }
    .elc-search-suggestion-container {
      display: none;
    }
    .elc-search-results-message-wrapper {
      @include breakpoint($medium-up) {
        margin-bottom: 16px;
      }
    }
  }
  .elc-search-grid-skeleton,
  .elc-product-brief-view {
    @include breakpoint($medium-up) {
      grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
      grid-gap: 30px;
    }
  }
  .elc-grid-container {
    .elc-product-brief-view {
      .elc-product-item-wrapper,
      .elc-grid-item-product {
        line-height: 0;
        margin-top: 0;
        padding: 0;
        text-align: $ldirection;
        .elc-product-brief-wrapper {
          background-color: $cr19-bg-black;
          padding: 0;
        }
        .elc-product-brief-detail-wrapper {
          .elc-product-description-wrapper,
          .elc-product-price-row-wrapper,
          .elc-size-picker-wrapper,
          .elc-product-shade-picker-wrapper,
          .elc-product-size-wrapper,
          .elc-product-price-wrapper,
          .elc-product-cta-wrapper {
            display: none;
          }
          .elc-product-rating-wrapper {
            justify-content: center;
          }
        }
        .elc-product-display-name,
        .elc-product-subheader {
          color: $color-white;
          line-height: initial;
        }
        .elc-product-display-name {
          font-family: $header-font-bold;
          line-height: 1.2;
          font-size: 20px;
          font-weight: 100;
          text-decoration: none;
        }
        .elc-product-subheader {
          font-family: $base-font-family;
          font-size: 16px;
          margin: 0;
        }
      }
    }
    margin: 0 0 40px;
    padding-top: 5px;
  }

  .elc-gnav-search-icon-wrapper {
    #{$ldirection}: 0;
    top: 16px;
    .elc-icon-search {
      mask: none;
      -webkit-mask: none;
      &:before {
        content: '';
        width: auto;
        height: 17px;
        background: url('/media/images/global/icons/search_white.svg') no-repeat center transparent;
        display: inline-block;
        padding-#{$ldirection}: 45px;
        @include breakpoint($medium-up) {
          padding-#{$ldirection}: 26px;
        }
      }
    }
  }
  .elc-grid-item-tout {
    padding: 0;
    .elc-basic-tout__link-content {
      margin: 0;
      max-width: 100%;
    }
  }
  .elc-heading--5 {
    text-align: center;
    color: $color-white;
    padding: 10px;
    font-family: $header-font-bold;
    font-size: 20px;
    margin: 0;
    line-height: 1.1;
  }
  .elc-search-term-wrapper {
    background: $color-black;
  }
  .elc-product-brief-detail-wrapper {
    padding-top: 0;
    padding-#{$ldirection}: 0;
    background-color: $color-black;
    margin: 0;
    align-items: flex-start;
  }
  .elc-product-name-wrapper {
    margin: 0;
  }
  .elc-stars-simplified-stars:before {
    background: url('/media/images/global/stars/gg_star_white.svg') repeat-x;
  }
}
.site-header {
  .site-utils__nav {
    .search-overlay-displayed & {
      z-index: 998;
    }
  }
}
